import {
  HistoryEdu,
  AdminPanelSettings,
  ReceiptLong,
  School,
  CastForEducation
} from "@mui/icons-material";

export const SMART_ESHOP = "Smart e-shop";
export const SMART_KNOWLEDGE = "Smart Knowledgebase";
export const U_AUTHOR_IT = "uAuthorIt";
export const U_TEACH_IT = "uTeachIt";
export const U_STUDY_IT = "uStudyIt";
export const SMART_ALUMNI_WORK_XLE = "Smart Alumni@Work (LXE)";
export const SMART_CAREER_CONSULTANT = "Smart Career Consultant";
export const ADMIN = "Admin";

export const icons = [
  {
    label: SMART_ESHOP,
    icon: <School />,
  },
  {
    label: SMART_KNOWLEDGE,
    icon: <School />,
  },
  {
    label: U_AUTHOR_IT,
    icon: <HistoryEdu />,
  },
  {
    label: U_TEACH_IT,
    icon: <CastForEducation />,
  },
  {
    label: U_STUDY_IT,
    icon: <ReceiptLong />,
  },
  {
    label: SMART_ALUMNI_WORK_XLE,
    icon: <School />,
  },
  {
    label: SMART_CAREER_CONSULTANT,
    icon: <School />,
  },
  {
    label: ADMIN,
    icon: <AdminPanelSettings />,
  },
];
