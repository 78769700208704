import React from "react";
import styles from "./input.module.scss";

const Input = (props) => {
  const { register, name, label, type, errors, required } = props;
  return (
    <label className={styles.input}>
      <span>   {required && <span className={styles.required}></span>}
        {label}</span>

      <input required={required} type={type} {...register(name)} />
      {errors[name] && <p>{errors[name].type}</p>}
    </label>
  );
};

export default Input;
