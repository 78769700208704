import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const styles = {
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

const languagesList = [
  {
    label: "Arabic",
    value: "ar",
    flag: "🇪🇬",
  },
  {
    label: "English",
    value: "en",
    flag: "🇺🇸",
  },
  {
    label: "French",
    value: "fr",
    flag: "🇫🇷",
  },
  {
    label: "Italian",
    value: "it",
    flag: "🇮🇹",
  },
  {
    label: "Spanish",
    value: "es",
    flag: "🇪🇸",
  },
  {
    label: "German",
    value: "de",
    flag: "🇩🇪",
  },
];

const SelectLanguage = (props) => {
  const { label, name, preferredLanguages, onChangePreferedLanguages } = props;
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        label={name}
        value={preferredLanguages[name]}
        onChange={(e) => onChangePreferedLanguages(name, e.target.value)}
      >
        {languagesList.map((item) => (
          <MenuItem key={item.value} value={item.value} style={styles.item}>
            <span>{item.label}</span>
            &nbsp;
            <span>{item.flag}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectLanguage;
