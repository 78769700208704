import React, { useState } from "react";
import Input from "../../Input/Input";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import useStore from "../../../store";


const AddType = () => {
  const params = useParams();
  const {
    data,
    tenantId: tId,
  } = useStore((state) => state);
  const fetchData = async () => {
    const { id } = params;

    if (!id) {
      return {
        typeName: "",
        description: "",
        icon: "",
        questionOrExplanation: "",
        labels: "",
        templateName: "",
        templateId: "",
        htmlSeparator: "",
        repeatedString: "",
        repeated2: "",
        repeated3: "",
        abstractParameter: "",
        originedJson: "",
        modifiedJson: "",
        exampleId: "",

      };
    } else {
      const res = await axios
      .get(`https://apis.eduedges.com/api/lom/io-types/${id}`, {
        headers: {
          authorization: JSON.stringify({
            Authorization: data.accessToken?.jwtToken,
            IdToken: data.idToken?.jwtToken,
            TENANT_ID: tId,
          })
        }
      })
      res.data.labels = JSON.stringify(res.data.labels)
      res.data.abstractParameter = JSON.stringify(res.data.abstractParameter)

      return res.data

    }
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: async () => await fetchData(),
  });


  const onSubmit = async (values) => {
    const { id } = params;
    try {
      if (id) {
        const res = await axios.patch(`https://apis.eduedges.com/api/lom/io-types/${id}`, {
          ...values,
          abstractParameter: JSON.parse(values.abstractParameter),
        });  
        toast.success("Type updated successfully!");
      } else {
        const res = await axios.post(`https://apis.eduedges.com/api/lom/io-types`, {
          ...values,
          abstractParameter: JSON.parse(values.abstractParameter),
        });  
        toast.success("Type added successfully!");
      }
      console.log("data= ", data);
    } catch (error) {
      console.log(error);
      window.alert(error)

    }
  };

  const [file, setFile] = React.useState();
  const handleChange = async (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  return (
    <div className="container" style={{backgroundColor: "white"}}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset style={{padding: "20px"}} className="border pl-4 pr-4 pt-3 ">
          
          <Input
            label="typeName"
            name="typeName"
            type="text"
            register={register}
            errors={errors}
            required
          />
          <Input

            label="description"
            name="description"
            type="text"
            register={register}
            errors={errors}
          />
               <label  htmlFor="category" class="required" style={{fontSize: "1.25rem"}}>
                Category
              </label>
        <select 
        style={{marginTop: "10px", height:  "3rem", marginBottom: "40px"}}
                name="category"
                id="category"
                className="form-control"
                value=""
                required
                register={register}
              >
                {["Q", "X", "G", "B", "SI"].map(item =>
                  <option value={item}>{item}</option>)}
              </select>
          <Input
            label="labels"
            name="labels"
            type="text"
            register={register}
            errors={errors}
            required
          />

          <Input
            label="abstractParameter"
            name="abstractParameter"
            type="text"
            register={register}
            errors={errors}
            required

          />
          <Input
            label="repeatedString"
            name="repeatedString"
            type="text"
            register={register}
            errors={errors}
          />
          <Input
            label="repeated2"
            name="repeated2"
            type="text"
            register={register}
            errors={errors}
          />
          <Input
            label="repeated3"
            name="repeated3"
            type="text"
            register={register}
            errors={errors}
          />
          <Input
            label="htmlSeparator"
            name="htmlSeparator"
            type="text"
            register={register}
            errors={errors}
          />
          <Input
            label="templateName"
            name="templateName"
            type="text"
            register={register}
            errors={errors}
          />
          <Input
            label="templateId"
            name="templateId"
            type="text"
            register={register}
            errors={errors}
            required

          />

          <Input
            label="originalJson"
            name="originalJson"
            type="text"
            register={register}
            errors={errors}
            
  required
          />
          <Input
            label="modifiedJson"
            name="modifiedJson"
            type="text"
            register={register}
            errors={errors}
            required
          />
          <Input
            label="exampleId"
            name="exampleId"
            type="text"
            register={register}
            errors={errors}
          />

<Input
            label="Icon"
            name="icon"
            type="file"
            register={register}
            errors={errors}
          />


        </fieldset>
        <div className="mb-4 text-center">
          <Button variant="contained" type="submit">
            submit
          </Button>
        </div>

      </form>
    </div>
  );
};

export default AddType;
