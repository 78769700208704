import React, { useEffect, useState } from "react";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { LockOutlined } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import useStore from "../../store";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import config from "../../config";

export default function VerifyEmail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [poolParams, setPoolParams] = useState({});
  const [loading, setLoading] = useState(false);

  const { error, setError, tenantId, setTenantId } = useStore((state) => state);

  useEffect(() => {
    setTenantId(
      searchParams.get("tenantId") ? searchParams.get("tenantId") : null
    );
    if (tenantId) {
      setError("");
      axios
        .get(`${config.tenantsURL}/tenant_id/${tenantId}`)
        .then((response) => {
          const tenant = response.data.tenant;
          const poolId = tenant?.issuer.split("/")[3];
          const clientId = tenant?.app_client_id;
          setPoolParams({ UserPoolId: poolId, ClientId: clientId });
        });
    } else {
      setLoading(false);
      setError("There is no tenant provided");
    }
  }, [searchParams, setError, setPoolParams, setTenantId, tenantId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (username === "" || code === "") {
      setError("* Fields are required");
      return;
    }
    if (poolParams.UserPoolId && poolParams.ClientId) {
      const Pool = new CognitoUserPool(poolParams);
      var user = new CognitoUser({
        Username: username,
        Pool,
      });

      user.confirmRegistration(code, true, function (err, result) {
        if (err) {
          setError(err.message || JSON.stringify(err));
          return;
        }
        navigate(`/?tenantId=${tenantId}`);
      });
    } else {
      setError(`Tenant ${tenantId} not found`);
    }
  };

  const handleResendCode = () => {
    if (username === "") {
      setError("Username Field is required");
      return;
    }
    const Pool = new CognitoUserPool(poolParams);

    var user = new CognitoUser({
      Username: username,
      Pool,
    });
    user.resendConfirmationCode(function (err, result) {
      if (err) {
        setError(err.message || JSON.stringify(err));
        return;
      }
    });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Email Verification
          </Typography>{" "}
          {error && (
            <Alert sx={{ width: "100%" }} severity="error">
              {error}
            </Alert>
          )}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoFocus
              value={username}
              onChange={(event) => setUsername(event.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="code"
              label="Verification Code"
              helperText="Please check your email for verification code"
              id="code"
              value={code}
              onChange={(event) => setCode(event.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2"></Link>
              </Grid>
              <Grid item>
                <Link onClick={handleResendCode} variant="body2">
                  Resend Verification Code
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}
