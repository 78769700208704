import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Chip,
  TextField,
  Typography,
  InputAdornment,
  Alert,
} from "@mui/material";
import {
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import useStore from "../../store";
import { toast, ToastContainer } from "react-toastify";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";

export default function ProfileCard(props) {
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);
  const [changeEmailDialog, setChangeEmailDialog] = useState(false);
  const [verificationCodeDialog, setVerificationCodeDialog] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const { user, setUser, data } = useStore((state) => state);

  const checkPassword = (password = "") => {
    if (password) {
      if (password.length < 8) {
        setError("password length must be more than 8 characters");
      } else {
        setError("");
      }
    }
  };

  const notifyError = (message) => {
    toast.error(message, {
      theme: "colored",
      closeOnClick: true,
      hideProgressBar: true,
      autoClose: 3000,
    });
  };
  const notifySuccess = (message) => {
    toast.success(message, {
      theme: "colored",
      closeOnClick: true,
      hideProgressBar: true,
      autoClose: 3000,
    });
  };

  const handleClose = () => {
    setChangePasswordDialog(false);
    setChangeEmailDialog(false);
    setVerificationCodeDialog(false);
    setShowOldPassword(false);
    setShowNewPassword(false);
    setOldPassword("");
    setNewPassword("");
    setError("");
  };

  const changePassword = (oldPassword, newPassword) => {
    const Pool = new CognitoUserPool({
      UserPoolId: data?.accessToken?.payload.iss.split("/")[3],
      ClientId: data?.accessToken?.payload.client_id,
    });
    const cognitoUser = Pool.getCurrentUser();
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
        setError(err);
        return;
      }
      cognitoUser.changePassword(
        oldPassword,
        newPassword,
        function (err, result) {
          if (err) {
            console.log(err.message);
            setError(err.message);
            return;
          }
          setChangePasswordDialog(false);
          setShowOldPassword(false);
          setShowNewPassword(false);
          setOldPassword("");
          setNewPassword("");
          notifySuccess("Your password changed successfully");
          setError("");
        }
      );
    });
  };

  const updateUserInfo = (userInfo) => {
    const Pool = new CognitoUserPool({
      UserPoolId: data?.accessToken?.payload.iss.split("/")[3],
      ClientId: data?.accessToken?.payload.client_id,
    });
    const cognitoUser = Pool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.log(err);
          setError(err.message);
        }
      });
    }
    const attributeList = [];

    userInfo.map((x) => attributeList.push(new CognitoUserAttribute(x)));
    cognitoUser.updateAttributes(attributeList, function (err, result) {
      if (err) {
        console.log(err.message || JSON.stringify(err));
        setError(err.message);
        return;
      } else {
        cognitoUser.getUserAttributes((err, attributes) => {
          if (err) {
            console.log(err.message);
            setError(err.message);
          } else {
            setUser(attributes);
            notifySuccess("Your email changed successfully");
          }
        });
      }
    });
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const changeEmail = (newEmail) => {
    if (newEmail && isValidEmail(newEmail)) {
      updateUserInfo([{ Name: "email", Value: newEmail }]);
      setChangeEmailDialog(false);
      setVerificationCodeDialog(true);
    } else {
      setError("Invalid email address format");
    }
  };

  const emailVerification = (verificationCode) => {
    if (verificationCode) {
      const Pool = new CognitoUserPool({
        UserPoolId: data?.accessToken?.payload.iss.split("/")[3],
        ClientId: data?.accessToken?.payload.client_id,
      });
      const cognitoUser = Pool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.log(err);
            setError(err.message);
          } else {
            cognitoUser.verifyAttribute("email", verificationCode, {
              onSuccess: function (result) {
                setVerificationCodeDialog(false);
                setTimeout(() => {
                  cognitoUser.getUserAttributes((err, attributes) => {
                    if (err) {
                      console.log(err.message);
                      setError(err.message);
                    } else {
                      setUser(attributes);
                      setError("");
                    }
                  });
                }, 1000);
                notifySuccess("Your email is verified successfully");
              },
              onFailure: function (err) {
                console.log(err.message || JSON.stringify(err));
                setError(err.message);
              },
            });
          }
        });
      }
    } else {
      setError("Enter verification code");
    }
  };

  return (
    <>
      <ToastContainer />
      <Card {...props}>
        <CardContent>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Avatar
              // src={props.user.avatar}
              sx={{
                height: 64,
                mb: 2,
                width: 64,
              }}
            />
            <Typography color="textPrimary" gutterBottom variant="h5">
              {user?.given_name}
            </Typography>
            <Typography color="textSecondary" gutterBottom variant="body1">
              {user?.["custom:role"]?.toUpperCase()}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {user?.email}
            </Typography>
            <Chip
              onClick={() => {
                if (user?.email_verified === "false") {
                  setVerificationCodeDialog(true);
                }
              }}
              sx={{ mt: 2, p: 1 }}
              label={
                user?.email_verified === "true" ? "verified" : "unverified"
              }
              size="small"
              color={user?.email_verified === "true" ? "primary" : "error"}
            />
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            fullWidth
            variant="text"
            onClick={() => setChangeEmailDialog(true)}
          >
            Change Email
          </Button>
          <Button
            color="primary"
            fullWidth
            variant="text"
            onClick={() => setChangePasswordDialog(true)}
          >
            Change Password
          </Button>
        </CardActions>
      </Card>
      <Dialog fullWidth maxWidth="sm" open={changePasswordDialog}>
        <DialogTitle
          style={{
            background: "#fafafa",
            color: "primary",
          }}
        >
          Change Password
        </DialogTitle>
        <Divider />{" "}
        {error && (
          <Alert sx={{ width: "100%" }} severity="error">
            {error}
          </Alert>
        )}
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="oldPassword"
                label="Old Password"
                type={showOldPassword ? "text" : "password"}
                id="oldPassword"
                value={oldPassword}
                onChange={(event) => setOldPassword(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => setShowOldPassword(!showOldPassword)}
                    >
                      {showOldPassword ? (
                        <VisibilityOutlined />
                      ) : (
                        <VisibilityOffOutlined />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="newPassword"
                label="New Password"
                type={showNewPassword ? "text" : "password"}
                id="newPassword"
                value={newPassword}
                // helperText={error}
                onChange={(event) => {
                  checkPassword(event.target.value);
                  setNewPassword(event.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? (
                        <VisibilityOutlined />
                      ) : (
                        <VisibilityOffOutlined />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            onClick={() => changePassword(oldPassword, newPassword)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="sm" open={changeEmailDialog}>
        <DialogTitle
          style={{
            background: "#fafafa",
            color: "primary",
          }}
        >
          Change Email
        </DialogTitle>
        <Divider />
        {error && (
          <Alert sx={{ width: "100%" }} severity="error">
            {error}
          </Alert>
        )}
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="newEmail"
                label="New Email"
                type="email"
                id="newEmail"
                value={newEmail}
                onChange={(event) => setNewEmail(event.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            onClick={() => {
              changeEmail(newEmail);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={verificationCodeDialog}
        onClose={handleClose}
      >
        <DialogTitle
          style={{
            background: "#fafafa",
            color: "primary",
          }}
        >
          Email Verification Code
        </DialogTitle>
        <Divider />
        <DialogContent>
          {error && (
            <Alert sx={{ width: "100%" }} severity="error">
              {error}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            id="verificationCode"
            label="Verification Code"
            fullWidth
            variant="outlined"
            helperText="Please check your email for verification code"
            onChange={(e) => {
              e.preventDefault();
              setVerificationCode(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            onClick={() => {
              emailVerification(verificationCode);
            }}
          >
            Verify
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
