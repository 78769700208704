import React from "react";
import "./style.css";
export default function Footer(props) {
  return (
    <footer className="footer">
      <p>
        Copyright © {new Date().getFullYear()} SCube for Education Technology.
        All Rights Reserved
      </p>
    </footer>
  );
}
