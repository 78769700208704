import React, { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import useStore from "../../../store";
import axios from "axios";
import { toast } from "react-toastify";

const url = "https://apis.eduedges.com/api";

export default function IQuestionsForm(props) {
  const [searchParams] = useSearchParams();

  const [title, setTitle] = useState("");
  const [domainName, setDomainName] = useState("");
  const [domainId, setDomainId] = useState("");
  const [subDomainName, setSubDomainName] = useState("");
  const [subDomainId, setSubDomainId] = useState("");
  const [conceptName, setConceptName] = useState("");
  const [conceptId, setConceptId] = useState("");
  const [domains, setDomains] = useState([])
  const [subDomains, setSubDomains] = useState([])
  const [concepts, setConcepts] = useState([])
  const [type, setType] = useState("")
  const [id, setId] = useState(searchParams.get("id"))
  const [language, setLanguage] = useState("en")
  const [iRoles, setIRoles] = useState([])
  const [instructionalRole, setInstructionalRole] = useState("")
  const [owner, setOwner] = useState("public")
  const [input, setInput] = useState("visual")
  const [complexity, setComplexity] = useState(1)
  const [ioCategory, setIoCategory] = useState("")
  const [ioParameters, setIoParameters] = useState("")
  const [cognitive, setCognitive] = useState("remember")
  const [types, setTypes] = useState([])
  const { data, tenantId, username } = useStore((state) => state);
  const headers = React.useMemo(
    () => ({
      Authorization: JSON.stringify({
        Authorization: data.accessToken?.jwtToken,
        IdToken: data.idToken?.jwtToken,
        TENANT_ID: tenantId,
      }),
    }),
    [data, tenantId]
  );


  useEffect(() => {
    if (!domains.length) {
      axios.get(`${url}/ontologypy/domains/parents`, { headers }).then(async res => {
        const ids = []
        const arr = []
        for await (let item of res.data) {
          if (!ids.includes(item.id)) { arr.push(item); ids.push(item.id) }
        }
        setDomains(arr)
      }).catch(err => {
        showToast("Failed to load domains!")
      })
    }
  }, [domains]);

  useEffect(() => {
    if (!iRoles.length) {
      axios
        .get(`${url}/dbm/instructionalroles`, { headers }).then(res => {
          setIRoles(res.data.instructionalRoles)
          setInstructionalRole(res.data.instructionalRoles[0])
        })
    }
  })

  useEffect(() => {
    if (!types.length) {
      axios
        .get(`${url}/lom/io-types?category=Q`, { headers }).then(res => {
          setTypes(res.data)
        })
    }
  })

  useEffect(() => {
    if (id) {
      axios.get(`${url}/lom/los/${id}`, { headers }).then(res => {
        const { title, language, complexity, cognitive, input, instructionalrole, conceptId, conceptName, domainName, domainId, subDomainName, subDomainId, owner, ioType, ioCategory, ioParameters } = res.data
        setTitle(title)
        setLanguage(language)
        setComplexity(complexity)
        setCognitive(cognitive)
        setInstructionalRole(instructionalrole)
        setInput(input)
        setDomainId(domainId)
        setDomainName(domainName)
        getSubDomains(domainId)
        setSubDomainId(subDomainId)
        setSubDomainName(subDomainName)
        getConcepts(subDomainId)
        setConceptId(conceptId)
        setConceptName(conceptName)
        setOwner(owner)
        setIoCategory(ioCategory)
        setType(ioType)
        setIoParameters(ioParameters)
      }).catch(err => {

      })
    }
  }, [headers]);



  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
    });
  };
  const getSubDomains = (parentId) => {
    axios.get(`${url}/ontologypy/domains/${parentId}/subdomains`, { headers }).then(async res => {
      setSubDomains(res.data)
    }).catch(err => {
      console.log(err)
    })
  }

  const handleClick = (event) => {
    event.preventDefault();
  };
  const getConcepts = (domainId) => {
    axios.get(`${url}/ontologypy/domains/${domainId}/concepts`, { headers }).then(async res => {
      setConcepts(res.data)
    }).catch(err => {
      console.log(err)
    })
  }
  return (
    <form autoComplete="off" noValidate {...props} onSubmit={handleClick}>
      <Card>
        <CardHeader title="New interactive question" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={10} xs={12}>
              <TextField
                fullWidth
                label="Title"
                name="title"
                onChange={(e) => setTitle(e.target.value)}
                required
                value={title}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <FormControl fullWidth required>
                <InputLabel>Object owner</InputLabel>
                <Select
                  label="owner"
                  name="owner"
                  value={owner}
                  onChange={(event) => setOwner(event.target.value)}
                >
                  <MenuItem value={username}>me</MenuItem>
                  <MenuItem value={tenantId}>{tenantId}</MenuItem>
                  <MenuItem value="public">public</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={5}>
              <FormControl fullWidth required>
                <InputLabel>Domain</InputLabel>
                <Select
                  label="domain"
                  name="domain"
                  value={domainId}
                  onChange={(event) => {
                    setDomainId(event.target.value)
                    setDomainName(event.target.name)
                    getSubDomains(event.target.value)
                  }}
                >
                  {domains.map(item => <MenuItem value={item.id}>{item.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={5}>
              <FormControl fullWidth required>
                <InputLabel>Sub Domain</InputLabel>
                <Select
                  label="sub-domain"
                  name="sub-domain"
                  value={subDomainId}
                  onChange={(event) => {
                    setSubDomainId(event.target.value)
                    setSubDomainName(event.target.name)
                    getConcepts(event.target.value)
                  }}
                >
                  {subDomains.map(item => <MenuItem value={item.id}>{item.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={5}>
              <FormControl fullWidth required>
                <InputLabel>Topic</InputLabel>
                <Select
                  label="concept"
                  name="concept"
                  value={conceptId}
                  onChange={(event) => {
                    setConceptId(event.target.value)
                    setConceptName(event.target.name)
                  }}
                >
                  {concepts.map(item => <MenuItem value={item.id}>{item.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={5}>
              <FormControl fullWidth required>
                <InputLabel>Language</InputLabel>
                <Select
                  label="language"
                  name="language"
                  value={language}
                  onChange={(event) => setLanguage(event.target.value)}
                >
                  {
                    [
                      { value: "en", label: "English" }, { value: "ar", label: "Arabic" }, { value: "fr", label: "French" },
                      { value: "es", label: "Spanish" }, { value: "it", label: "Italian" }, { value: "de", label: "German" }
                    ].map(item =>
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={5}>
              <FormControl fullWidth required>
                <InputLabel>Type</InputLabel>
                <Select
                  label="type"
                  name="type"
                  value={type}
                  onChange={(event) => {
                    setType(event.target.value)
                  }}
                >
                  {types.map(item => <MenuItem value={item.typeName}>{item.typeName}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={5}>
              <FormControl fullWidth required>
                <InputLabel>Cognitive</InputLabel>
                <Select
                  label="cognitive"
                  name="cognitive"
                  value={cognitive}
                  onChange={(event) => setCognitive(event.target.value)}
                >
                  {["remember", "understand", "apply", "analyze", "evaluate", "create"].map(item =>
                    <MenuItem value={item}>{item}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={5}>
              <FormControl fullWidth>
                <InputLabel>Complexity</InputLabel>
                <Select
                  label="complexity"
                  name="complexity"
                  value={complexity}
                  onChange={(event) => {
                    setComplexity(event.target.value)
                  }}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => <MenuItem value={item}>{item}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={5}>
              <FormControl fullWidth required>
                <InputLabel>Instructional role</InputLabel>
                <Select
                  label="instructionalrole"
                  name="instructionalrole"
                  value={instructionalRole}
                  onChange={(event) => {
                    setInstructionalRole(event.target.value)
                  }}
                >
                  {iRoles.map(item => <MenuItem value={item}>{item}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={5}>
              <FormControl fullWidth>
                <InputLabel>Input</InputLabel>
                <Select
                  label="input"
                  name="input"
                  value={input}
                  onChange={(event) => {
                    setInput(event.target.value)
                  }}
                >
                  {["visual", "verbal"].map(item => <MenuItem value={item}>{item}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            //justifyContent: "flex-start",
            p: 2,
            marginLeft: "150px"
          }}
        >
          <Button color="primary" variant="contained" type="submit" style={{ marginLeft: "50px", marginRight: "60px" }}>
            Manual form
          </Button>
          <Button color="primary" variant="contained" type="submit" style={{ marginLeft: "50px", marginRight: "60px" }}>
            scan and ocr
          </Button>
          <Button color="primary" variant="contained" type="submit" style={{ width: "150px", marginLeft: "50px", marginRight: "60px" }}>
            cancel
          </Button>
        </Box>
      </Card>
    </form >
  );
}
