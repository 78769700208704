import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import {
  Avatar,
  Alert,
  Button,
  TextField,
  Link,
  Box,
  Grid,
  Typography,
  Container,
  InputAdornment,
} from "@mui/material";
import {
  LockOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useStore from "../../store";
import axios from "axios";
import config from "../../config";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [stage, setStage] = useState(1); // 1 = email stage, 2 = code stage
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [poolParams, setPoolParams] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { error, setError, tenantId, setTenantId } = useStore((state) => state);

  useEffect(() => {
    setError("");
    setLoading(false);
  }, [setError]);

  useEffect(() => {
    setTenantId(
      searchParams.get("tenantId") ? searchParams.get("tenantId") : ""
    );
    if (tenantId) {
      setError("");
      axios
        .get(`${config.tenantsURL}/tenant_id/${tenantId}`)
        .then((response) => {
          const tenant = response.data.tenant;
          const poolId = tenant?.issuer.split("/")[3];
          const clientId = tenant?.app_client_id;
          setPoolParams({ UserPoolId: poolId, ClientId: clientId });
        });
    } else {
      setLoading(false);
      setError("There is no tenant provided");
    }
  }, [searchParams, setError, setTenantId, tenantId]);

  const sendCode = (event) => {
    event.preventDefault();
    setError("");
    setLoading(true);

    if (username === "") {
      setError("* Username Field is required");
      setLoading(false);
      return;
    }
    if (poolParams.UserPoolId && poolParams.ClientId) {
      const Pool = new CognitoUserPool(poolParams);

      const user = new CognitoUser({
        Username: username,
        Pool,
      });

      user.forgotPassword({
        onSuccess: (data) => {
          setLoading(false);
          setStage(2);
        },
        onFailure: (err) => {
          setError(err.message);
          setLoading(false);
          console.error("onFailure:", err);
        },
      });
    } else {
      setError(`Tenant ${tenantId} not found`);
    }
  };

  const resetPassword = (event) => {
    event.preventDefault();
    setError("");
    setLoading(true);

    if (!code || !password || !confirmPassword) {
      setError("* Fields are required");
      setLoading(false);
      return;
    }
    if (password !== confirmPassword) {
      console.error("Passwords are not the same");
      setLoading(false);
      return;
    }

    const Pool = new CognitoUserPool(poolParams);

    const user = new CognitoUser({
      Username: username,
      Pool,
    });

    user.confirmPassword(code, password, {
      onSuccess: (data) => {
        setError("");
        setLoading(false);
        navigate(`/?tenantId=${tenantId}`);
      },
      onFailure: (err) => {
        setError(err.message);
        setLoading(false);
        console.error("onFailure:", err);
      },
    });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Forgotten Password
          </Typography>
          {error && (
            <Alert sx={{ width: "100%" }} severity="error">
              {error}
            </Alert>
          )}
          {stage === 1 && (
            <Box
              component="form"
              onSubmit={sendCode}
              noValidate
              sx={{ mt: 1, width: 396 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                label="Username"
                name="username"
                autoFocus
                value={username}
                onChange={(event) => setUsername(event.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Submit
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href={`/?tenantId=${tenantId}`} variant="body2">
                    Back
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="signup" variant="body2"></Link>
                </Grid>
              </Grid>
            </Box>
          )}
          {stage === 2 && (
            <Box
              component="form"
              onSubmit={resetPassword}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <VisibilityOutlined />
                      ) : (
                        <VisibilityOffOutlined />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <VisibilityOutlined />
                      ) : (
                        <VisibilityOffOutlined />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Verification Code"
                name="code"
                value={code}
                helperText="Please check your email for verification code"
                onChange={(event) => setCode(event.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Reset Password
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link variant="body2" onClick={() => setStage(1)}>
                    Back
                  </Link>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
}
