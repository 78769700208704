import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Grid,
  Link,
  Button,
  TextField,
  Container,
  Alert,
  Avatar,
  Box,
  Typography,
  InputAdornment,
} from "@mui/material";
import {
  LockOutlined,
  SettingsRemoteOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import useStore from "../../store";
import config from "../../config";
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import axios from "axios";
import Profile from "../Profile/Profile";
import Layout from "../Layout/Layout";

let cognitoUser;

export default function Login() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [stage, setStage] = useState(1);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [poolParams, setPoolParams] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const {
    login,
    tenantId,
    setTenantId,
    error,
    setError,
    setUser,
    setRole,
    isLoggedIn,
  } = useStore((state) => state);

  console.log("isLoggedIn= ", isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/profile");
    }
    setError("");
    setLoading(false);
  }, [setError, stage, tenantId, isLoggedIn, navigate]);

  useEffect(() => {
    const parentUrl = document.referrer;
    const tenant = parentUrl.includes("ipsznc")
      ? "IPSZNC"
      : parentUrl.includes("ipsmansoura")
      ? "IPSMansoura"
      : "azharengineering2020";
    setTenantId(tenant);

    if (tenantId) {
      localStorage.setItem("tenantId", tenantId);
      setError("");
      axios
        .get(`${config[tenantId].tenantsURL}/tenant_id/${tenantId}`)
        .then((response) => {
          const tenant = response.data.tenant;
          const poolId = tenant?.issuer.split("/")[3];
          const clientId = tenant?.app_client_id;
          setPoolParams({ UserPoolId: poolId, ClientId: clientId });
        });
    } else {
      setLoading(false);
      setError("There is no tenant provided");
    }
  }, [searchParams, setError, setPoolParams, setTenantId, tenantId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (username === "" || password === "") {
      setError("* Fields are required");
      setLoading(false);
      return;
    }
    if (poolParams.UserPoolId && poolParams.ClientId) {
      const Pool = new CognitoUserPool(poolParams);
      cognitoUser = new CognitoUser({ Username: username, Pool });

      const authDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (data) => {
          cognitoUser.getUserAttributes((err, attributes) => {
            if (err) {
            } else {
              login(data, attributes);
              setUser(attributes);
              setRole(data);
              navigate("/profile");
            }
          });

          // setStage(2);
          setLoading(false);
        },
        onFailure: (err) => {
          setStage(1);
          setLoading(false);
          setError(err.message);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          setStage(3);
          setError("");
          setLoading(false);
          delete userAttributes.email_verified;
        },
      });
    } else {
      setError(`Tenant ${tenantId} not found`);
    }
  };

  const handleNewPassword = (event, newPassword) => {
    event.preventDefault();
    cognitoUser.completeNewPasswordChallenge(newPassword, null, {
      onSuccess: (data) => {
        setStage(1);
        setLoading(false);
      },
      onFailure: (err) => {
        setError(err.message);
        setLoading(false);
      },
    });
  };

  return (
    <>
      {stage === 1 && !isLoggedIn && (
        <>
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Login
              </Typography>
              {error && (
                <Alert sx={{ width: "100%" }} severity="error">
                  {error}
                </Alert>
              )}
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoFocus
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityOutlined />
                        ) : (
                          <VisibilityOffOutlined />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  Login
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link
                      href={`forgot-password/?tenantId=${tenantId}`}
                      variant="body2"
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </>
      )}
      {stage === 3 && (
        <>
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Set New Password
              </Typography>
              {error && (
                <Alert sx={{ width: "100%" }} severity="error">
                  {error}
                </Alert>
              )}
              <Box
                component="form"
                width={396}
                onSubmit={(event) => handleNewPassword(event, newPassword)}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  label="Password"
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(event) => setNewPassword(event.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Container>
        </>
      )}
    </>
  );
}
