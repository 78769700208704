import React, { useEffect, useState } from "react";
import { List } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useStore from "../../store";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import Item from "./Item/Item";
import { list } from "../settings";

const Sidebar = (props) => {
  const { open, toggleDrawer } = props;
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [selectedItemId, setSelectedItemId] = useState();
  const [isOpen, setIsOpen] = React.useState(false);

  const { data, tenantId, isLoggedIn } = useStore((state) => state);

  const handleListItemClick = (index) => {
    setSelectedItemId(index);
  };

  const onHover = () => {
    if (!isOpen) {
      toggleDrawer();
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      const Pool = new CognitoUserPool({
        UserPoolId: data?.accessToken?.payload.iss.split("/")[3],
        ClientId: data?.accessToken?.payload.client_id,
      });
      const cognitoUser = Pool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.log(err.message);
          } else {
            setRole(session?.idToken?.payload["custom:role"]);
          }
        });
      }
    } else {
      navigate(`/?tenantId=${tenantId}`);
    }
  }, [isLoggedIn, navigate, data, tenantId]);

  return (
    <List
      // onMouseOver={onHover}
      style={{ maxHeight: "100%", overflow: "auto" }}
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        height: "80vh",
      }}
    >
      {list.map((el) => (
        <Item
          key={el.id}
          node={el}
          role={role}
          selectedItemId={selectedItemId}
          handleListItemClick={handleListItemClick}
        />
      ))}
    </List>
  );
};

export default Sidebar;
