import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import SelectLanguage from "./SelectLanguageInput";

const Languages = (props) => {
  return (
    <Box>
      <Divider
        style={{
          margin: "2rem 0",
        }}
      />
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Typography variant="h6">Preferred Languages</Typography>
        </Grid>

        <Grid item md={6} sm={12}>
          <SelectLanguage {...props} label="Slides" name="slides" />
        </Grid>
        <Grid item md={6} sm={12}>
          <SelectLanguage {...props} label="Subitltes" name="subtitles" />
        </Grid>
        <Grid item md={6} sm={12}>
          <SelectLanguage {...props} label="Voice" name="voice" />
        </Grid>
        <Grid item md={6} sm={12}>
          <SelectLanguage {...props} label="Transcript" name="transcript" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Languages;
