import React from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { grey } from "@mui/material/colors";

const Item = (props) => {
  const {
    node: { id, icon, label, children, level, to, allowed },
    role,
    selectedItemId,
    handleListItemClick,
  } = props;
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const clickHandler = () => {
    setOpen((prevState) => !prevState);
    handleListItemClick(id);

    if (to) {
      navigate(to);
    }
  };

  const found = allowed?.find((userRole) => userRole === role);
  if (!allowed || !found) {
    return null;
  }

  return (
    <>
      <ListItemButton
        onClick={clickHandler}
        selected={selectedItemId === id}
        sx={{
          pl: level ? level * 4 : "auto",
          "&.Mui-selected": {
            backgroundColor: grey[300],
            borderRadius: "1rem 0 0 1rem",
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: "auto",
            marginRight: "1rem",
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "90%",
            }}
          >
            {label}
          </div>
        </ListItemText>
        {children ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItemButton>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((el) => (
              <Item
                key={el.id}
                node={el}
                role={role}
                selectedItemId={selectedItemId}
                handleListItemClick={handleListItemClick}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default Item;
