import React from "react";
import useStore from "../../store";
import { list } from "../settings";
import { useLocation } from "react-router-dom";
import PageNotFound from "../PageNotFound/PageNotFound";
import NewPageNotFound from "../Layout/NewPageNotFound/NewPageNotFound";

const withAuth = (Component) => () => {
  const { pathname } = useLocation();
  const { role } = useStore((state) => state);

  const isAllowed = () => {
    const rec = (item) => {
      const { to, children, allowed } = item;
      let found = false;
      if (to === pathname) {
        found = allowed?.some((AllowedRole) => AllowedRole === role);
      } else {
        found = children?.some((subItem) => rec(subItem)) || found;
      }
      return found;
    };

    const allowed = list.some((item) => rec(item));
    return allowed;
  };

  if (isAllowed()) {
    return <Component />;
  } else {
    return <NewPageNotFound />;
  }
};

export default withAuth;
