import React from "react";

import {
  createTheme,
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Layout from "../src/components/Layout/Layout";
import NormalLOs from "./components/Pages/NormalLOs";
import IOS from "./components/Pages/IOS";
import LOS from "./components/Pages/LOS";
import GameLOs from "./components/Pages/GameLOs";
import Courses from "./components/Pages/Courses";
import LXE from "./components/Pages/lxe";
import EBooks from "./components/Pages/EBooks";
import Login from "./components/Auth/Login";
import Signup from "./components/Auth/Signup";
import VerifyEmail from "./components/Auth/VerifyEmail";
import Profile from "./components/Profile/Profile";
import Lessons from "./components/Pages/Lessons";
import STCourses from "./components/Pages/STCourses";
import Glossary from "./components/Pages/Glossary";
import Questions from "./components/Pages/Questions";
import MicroLOs from "./components/Pages/MicroLOs";
import STLessons from "./components/Pages/STLessons";
import Tenants from "./components/Admin/Tenants";
import Users from "./components/Admin/Users";
import NewPageNotFound from "./components/Layout/NewPageNotFound/NewPageNotFound";
import ForgotPassword from "./components/Auth/ForgotPassword";
import IOTypesList from "./components/Pages/IOTypes/List";
import "bootstrap/dist/css/bootstrap.min.css";
import IOTypeForm from "./components/Pages/IOTypes/Form";
import IObjectsForm from "./components/Pages/iObjects/iObjectsForm";
import IQuestionsForm from "./components/Pages/IQuestions/iQuestionsForm";
import InteractiveObjects from "./components/Pages/interactive-objects";
import QuestionBank from "./components/Pages/question-bank";
import OldIBook from "./components/Pages/old-ibooks";
import TeachCourses from "./components/Pages/TeachCourses";
function App() {
  let theme = createTheme({
    palette: {
      primary: { main: "#0594a9" },
      secondary: { main: "#d2b96f" },
    },
  });
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route element={<Layout />}>
          <Route path="/profile" element={<Profile />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/lxe" element={<LXE />} />
          <Route path="/ebooks" element={<EBooks />} />
          <Route path="/los" element={<LOS />} />
          <Route path="/templates-ios" element={<IOS />} />
          <Route path="/normal-los" element={<NormalLOs />} />
          <Route path="/edu-games" element={<GameLOs />} />
          <Route path="/lessons" element={<Lessons />} />
          <Route path="/st-courses" element={<STCourses />} />
          <Route path="/st-books" element={<OldIBook />} />
          <Route path="/st-lessons" element={<STLessons />} />
          <Route path="/teach-courses" element={<TeachCourses />} />
          <Route path="/glossary" element={<Glossary />} />
          <Route path="/tenants" element={<Tenants />} />
          <Route path="/users" element={<Users />} />
          <Route path="/ios" element={<InteractiveObjects />} />
          <Route path="/io-form" element={<IObjectsForm />} />
          <Route path="/iqs" element={<QuestionBank />} />
          <Route path="/iq-form" element={<IQuestionsForm />} />
         <Route path="/questions" element={<Questions />} />
          <Route path="/micro-los" element={<MicroLOs />} />
          <Route path="/io-types" element={<IOTypesList />} />
          <Route path="/io-type-form" element={<IOTypeForm />} />
          <Route path="/io-type-form/:id" element={<IOTypeForm />} />

        </Route>
        <Route path="*" element={<NewPageNotFound />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
