import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../../store";
import withAuth from "../Auth/withAuth";

function EBooks() {
  const navigate = useNavigate();

  const { logout, setPageTitle, tenantId, isLoggedIn, data } = useStore(
    (state) => state
  );

  useEffect(() => {
    setPageTitle("Book Authoring");
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      logout();
      navigate(`/?tenantId=${tenantId}`);
    }
  }, []);

  return (
    <div>
      <iframe
        title="my-iframe"
        width="100%"
        height="1000px"
        src={`https://lom.eduedges.com/eBooks?id_token=${data.idToken.jwtToken}&access_token=${data.accessToken.jwtToken}&tenant_id=${tenantId}`}
      ></iframe>
    </div>
  );
}

export default withAuth(EBooks);
