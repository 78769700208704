import { CognitoUserPool } from "amazon-cognito-identity-js";

const baseURL = "https://apis.eduedges.com/api";
const tenantId = localStorage.getItem("tenantId");
const config = {
  azharengineering2020: {
    UserPool: new CognitoUserPool({
      UserPoolId: "eu-west-1_yeNM7X2En",
      ClientId: "17p7elrq7tqcqc494ghg4iei0i",
    }),
    // for local testing and developing
    // apiURL: "http://localhost:3002/api",
    // tenantsURL: "http://localhost:9000/api/tenants",

    // for production
    apiURL: baseURL + "/dbm",
    tenantsURL: baseURL + "/tm/tenants",
    TENANT_ID: "azharengineering2020",
  },
  IPSZNC: {
    UserPool: new CognitoUserPool({
      UserPoolId: "eu-west-1_dEgpxJE0k",
      ClientId: "4uj81cf0upgfocid1m1rjal51h",
    }),
    // for local testing and developing
    // apiURL: "http://localhost:3002/api",
    // tenantsURL: "http://localhost:9000/api/tenants",

    // for production
    apiURL: baseURL + "/dbm",
    tenantsURL: baseURL + "/tm/tenants",
    TENANT_ID: "IPSZNC",
  },
  IPSMansoura: {
    UserPool: new CognitoUserPool({
      UserPoolId: "eu-west-1_dZfKmrQSf",
      ClientId: "393v4h8rgdh2omfgoenb84206f",
    }),
    // for local testing and developing
    // apiURL: "http://localhost:3002/api",
    // tenantsURL: "http://localhost:9000/api/tenants",

    // for production
    apiURL: baseURL + "/dbm",
    tenantsURL: baseURL + "/tm/tenants",
    TENANT_ID: "IPSMansoura",
  },
  lomURL: 'lom'
};

export default config;
