import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const store = (set) => ({
  isLoggedIn: false,
  isAdmin: false,
  role: "",
  data: {},
  tenantId: "",
  user: null,
  pageTitle: "",
  pageLang: "en",
  selectedIndex: 0,
  error: "",
  tenants: [],
  users: [],
  setTenantId: (tenantId) => {
    set((state) => ({
      tenantId,
    }));
  },
  setPoolParams: (poolParams) => {
    set((state) => ({ poolParams }));
  },
  setUser: (attributes) => {
    const u = {};
    attributes.map((i) => (u[i.Name] = i.Value));

    set((state) => ({ user: u }));
  },
  setPageTitle: (title) => {
    set((state) => ({
      pageTitle: title,
    }));
  },
  setPageLang: (lang) => {
    set((state) => ({
      pageLang: lang,
    }));
  },
  setSelectedIndex: (index) => {
    set((state) => ({
      selectedIndex: index,
    }));
  },
  login: (data, attributes) => {
    const u = {};
    attributes.map((i) => (u[i.Name] = i.Value));
    console.log(data?.idToken?.payload)
    set((state) => ({
      isLoggedIn: true,
      user: u,
      username: data.idToken.payload["cognito:username"],
      data,
      error: "",
      isAdmin:
        data?.idToken?.payload["custom:role"] === "superadmin" ? true : false,
    }));
  },
  setRole: (data) => {
    set((state) => ({
      role: data?.idToken?.payload["custom:role"],
    }));
  },
  logout: () => {
    set((state) => ({
      isLoggedIn: false,
      data: {},
      isAdmin: false,
      user: {},
      selectedIndex: 0,
      error: "",
      tenants: [],
      users: [],
    }));
  },
  setError: (err) => {
    set((state) => ({
      error: err,
    }));
  },
  addTenant: (tenant) => {
    set((state) => ({
      tenants: [tenant, ...state.tenants],
    }));
  },
  removeTenant: (id) => {
    set((state) => ({
      tenants: state.tenants.filter((t) => t._id !== id),
    }));
  },
  setTenants: (tenants) => {
    set((state) => ({ tenants: tenants }));
  },
  addUser: (user) => {
    set((state) => ({
      users: [user, ...state.users],
    }));
  },
  removeUser: (username) => {
    set((state) => ({
      users: state.users.filter((u) => u.username !== username),
    }));
  },
  setUsers: (users) => {
    set((state) => ({ users: users }));
  },
});
const useStore = create(devtools(persist(store, { name: "store" })));
export default useStore;
