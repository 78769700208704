import React, { useEffect } from "react";
import useStore from "../../store";
import { useNavigate } from "react-router-dom";
import withAuth from "../Auth/withAuth";

function GameLOs() {
  const navigate = useNavigate();

  const { logout, setPageTitle, isLoggedIn, tenantId, data } = useStore(
    (state) => state
  );
  useEffect(() => {
    setPageTitle("Game Objects");
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      logout();
      navigate(`/?tenantId=${tenantId}`);
    }
  }, []);

  return (
    <>
      <div>
        <iframe
          title="my-iframe"
          width="100%"
          height="1000px"
          src={`https://lom.eduedges.com/edu-games?id_token=${data.idToken.jwtToken}&access_token=${data.accessToken.jwtToken}&tenant_id=${tenantId}`}
        ></iframe>
      </div>
    </>
  );
}

export default withAuth(GameLOs);
