import { Box, Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import ProfileCard from "./ProfileCard";
import ProfileDetails from "./ProfileDetails";
import useStore from "../../store";
import { useNavigate } from "react-router-dom";

export default function Profile(props) {
  const navigate = useNavigate();
  const { setPageTitle, setSelectedIndex, isLoggedIn, tenantId } = useStore(
    (state) => state
  );

  useEffect(() => {
    setPageTitle("Profile");
    setSelectedIndex(-1);
  }, [setPageTitle, setSelectedIndex]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(`/?tenantId=${tenantId}`);
    }
  }, [isLoggedIn, navigate, tenantId]);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <ProfileCard />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <ProfileDetails />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
