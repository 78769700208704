import { v4 as uuidv4 } from "uuid";
import { icons } from "./icons";
import items from "./data.json";

const getIcon = (item) => {
  const foundItem = icons.find((el) => el.label === item.label);
  return foundItem?.icon;
};

const enhanceItem = (item, level) => {
  const { children, to } = item;
  return {
    ...item,
    id: uuidv4(),
    icon: getIcon(item),
    level,
    children: children?.map((el) => enhanceItem(el, level + 1)) || null,
  };
};

const list = items.map((item) => {
  return enhanceItem(item, 0);
});

export { list };
