import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Logout, Person, Portrait } from "@mui/icons-material";
import useStore from "../../store";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { Link } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [username, setUsername] = useState("");
  // const [open] = useState(anchorEl);
  console.log("Navbar");

  const { logout, pageTitle, tenantId, data, isLoggedIn } = useStore(
    (state) => state
  );

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (isLoggedIn) {
      const Pool = new CognitoUserPool({
        UserPoolId: data?.accessToken?.payload.iss.split("/")[3],
        ClientId: data?.accessToken?.payload.client_id,
      });
      setUsername(Pool.getCurrentUser()?.getUsername());
    } else {
      navigate(`/?tenantId=${tenantId}`);
    }
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <AppBar position="static" sx={{ background: "#fafafa" }}>
        <Toolbar>
          <Box mt={1} mb={2}>
            <Link to="/">
              <img
                src="/static/images/scube.png"
                alt="scube logo"
                width={100}
              />
            </Link>
          </Box>

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "black", ml: 2 }}
          >
            {pageTitle}
          </Typography>
          <Stack direction="row" spacing={2}>
            {isLoggedIn ? (
              <Avatar
                id="profile-button"
                color="inherit"
                aria-controls={open ? "profile-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              ></Avatar>
            ) : (
              <Button color="secondary" href="/" component={"a"}>
                Log in
              </Button>
            )}
          </Stack>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            MenuListProps={{
              "aria-labelledby": "profile-button",
            }}
          >
            <MenuItem>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              Welcome {username}
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                navigate("/profile", { replace: true });
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <Portrait />
              </ListItemIcon>
              Profile
            </MenuItem>
            <MenuItem
              onClick={() => {
                localStorage.clear();
                logout();
                setAnchorEl(null);
                navigate(`/?tenantId=${tenantId}`);
                // window.close();
              }}
            >
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Divider />
    </>
  );
};

export default React.memo(Navbar);
