import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Country, City } from "country-state-city";
import { MuiTelInput } from 'mui-tel-input'

import {
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import useStore from "../../store";
import PreferredLanguages from "./PreferredLanguages";
import axios from "axios";
import { toast } from "react-toastify";

const url = "https://apis.eduedges.com/api/dbm/users/me";

export default function ProfileDetails(props) {
  const countries = Country.getAllCountries([]);

  const [given_name, setGivenName] = useState("");
  const [family_name, setFamilyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [gender, setGender] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [cities, setCities] = useState([]);
  const [preferredLanguages, setPreferredLanguages] = useState({
    slides: "",
    subtitles: "",
    voice: "",
    transcript: "",
  });

  const { user, setUser, data, tenantId } = useStore((state) => state);

  const headers = React.useMemo(
    () => ({
      Authorization: JSON.stringify({
        Authorization: data.accessToken?.jwtToken,
        IdToken: data.idToken?.jwtToken,
        TENANT_ID: tenantId,
      }),
    }),
    [data, tenantId]
  );

  useEffect(() => {
    setCities(City.getCitiesOfCountry(country));
  }, [country]);

  useEffect(() => {
    setGivenName(user?.given_name || "");
    setFamilyName(user?.family_name || "");
    setEmail(user?.email || "");
    setPhone(user?.phone_number || "");
    setCity(user["custom:city"] || "");
    setCountry(user["custom:country"] || "EG");
    setGender(user?.gender || "");
    setBirthdate(user?.birthdate || "");
  }, [user]);

  const getPreferredLanguages = useCallback(async () => {
    const res = await axios({ method: "GET", url, headers });
    const { preferredLanguages } = res.data.user;
    setPreferredLanguages((prevState) => ({
      ...prevState,
      ...preferredLanguages,
    }));
  }, [headers]);

  const savePreferredLanguages = async () => {
    await axios({
      method: "PUT",
      url: url,
      headers,
      data: {
        preferredLanguages,
      },
    });
  };

  useEffect(() => {
    getPreferredLanguages();
  }, [getPreferredLanguages]);

  const updateUserInfo = (userInfo) => {
    const Pool = new CognitoUserPool({
      UserPoolId: data?.accessToken?.payload.iss.split("/")[3],
      ClientId: data?.accessToken?.payload.client_id,
    });
    const cognitoUser = Pool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.log(err);
        }
      });
    }
    const attributeList = [];

    userInfo.map((x) => attributeList.push(new CognitoUserAttribute(x)));
    cognitoUser.updateAttributes(attributeList, function (err, result) {
      if (err) {
        console.log(err.message || JSON.stringify(err));
        return;
      } else {
        setTimeout(() => {
          cognitoUser.getUserAttributes((err, attributes) => {
            if (err) {
              console.log(err.message);
            } else {
              setUser(attributes);
            }
          });
        }, 1000);
      }
    });
  };

  const onChangePreferedLanguages = (key, value) => {
    setPreferredLanguages((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const showToast = (message) => {
    toast.success(message, {
      position: "top-center",
    });
  };

  const handleClick = (event) => {
    event.preventDefault();
    localStorage.setItem(
      "preferredLanguages",
      JSON.stringify(preferredLanguages)
    );
    savePreferredLanguages();
    const userInfo = [
      { Name: "email", Value: email },
      { Name: "given_name", Value: given_name },
      { Name: "family_name", Value: family_name },
      { Name: "gender", Value: gender },
      { Name: "birthdate", Value: birthdate },
      {
        Name: "phone_number",
        Value: phone_number,
      },
      { Name: "custom:country", Value: country },
      { Name: "custom:city", Value: city },
    ];
    updateUserInfo(userInfo);
    showToast("Profile updated successfully !");
  };

  return (
    <form autoComplete="off" noValidate {...props} onSubmit={handleClick}>
      <Card>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="First name"
                name="given-name"
                onChange={(e) => setGivenName(e.target.value)}
                required
                value={given_name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                name="lastName"
                onChange={(e) => setFamilyName(e.target.value)}
                required
                value={family_name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <InputLabel>Gender</InputLabel>
                <Select
                  label="Gender"
                  name="gender"
                  // autoComplete="gender"
                  value={gender}
                  onChange={(event) => setGender(event.target.value)}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item md={8} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                required
                value={email}
                variant="outlined"
              />
            </Grid> */}
            <Grid item md={6} xs={12}>
              <TextField
                required
                fullWidth
                id="date"
                label="Birthdate"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={birthdate}
                onChange={(event) => setBirthdate(event.target.value)}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Country</InputLabel>
                <Select
                  label="Country"
                  name="country"
                  defaultValue=""
                  value={country}
                  onChange={(event) => setCountry(event.target.value)}
                >
                  {countries.map((c) => {
                    return (
                      <MenuItem key={c.isoCode} value={c.isoCode}>
                        {c.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth required>
                <InputLabel>City</InputLabel>
                <Select
                  label="City"
                  name="city"
                  defaultValue=""
                  value={city}
                  onChange={(event) => setCity(event.target.value)}
                >
                  {cities.map((c, i) => {
                    return (
                      <MenuItem key={i} value={c.name}>
                        {c.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <MuiTelInput
                required
                fullWidth
                name="phone"
                id="phone"
                label="Phone Number"
                value={phone_number}
                defaultCountry={country.toLowerCase() || "eg"}
                onChange={(value) => setPhone(value)}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <PreferredLanguages
            preferredLanguages={preferredLanguages}
            onChangePreferedLanguages={onChangePreferedLanguages}
          />
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button color="primary" variant="contained" type="submit">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
}
